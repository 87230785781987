import React from "react";
import { Button, Pagination, Table } from "antd";
import Search from "antd/es/input/Search";
import {HiRefresh} from "react-icons/hi";

const TableComp = ({
  data,
  columns,
  loading,
  totalData,
  limitComp,
  pageComp,
  setLimitComp,
  setPageComp,
  fetchData,
}) => {
  const handleChange = (page, pageSize) => {
    setPageComp(page);
    setLimitComp(pageSize);
  };

  const handleSubmit = (value, event) => {
    fetchData(pageComp, limitComp, value);
  };

  return (
    <>
      <div className="headerTitle" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
        <Search
          style={{ marginBottom: "30px", width: "350px" }}
          placeholder="Search here.."
          loading={loading}
          enterButton
          onSearch={handleSubmit}
        />

        <Button
          type="primary"
          loading={loading}
          icon={<HiRefresh />}
          style={{ marginBottom: "30px", }}
          onClick={() => fetchData(pageComp, limitComp, "")}
        >
          Refresh Data
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          position: ["none", "none"],
        }}
      />
      <Pagination
        style={{ marginTop: "30px" }}
        total={totalData}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        pageSize={limitComp}
        hideOnSinglePage
        current={pageComp}
        onChange={handleChange}
      />
    </>
  );
};
export default TableComp;
