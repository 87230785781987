import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
import { useDrawer } from "../context/drawer";
import DrawerComp from "../components/Drower/Drawer";
import { useSelector } from "react-redux";
import axios from "axios";
import { Button, Modal, Steps, Tooltip, message } from "antd";
import { PiCurrencyInrBold } from "react-icons/pi";
import moment from "moment";

export default function EnachDetail() {
  const columns = [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      width: "20%",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "15%",
    },
    {
      title: "Final Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "15%",
    },
    {
      title: "Frequency",
      dataIndex: "payment_frequensy",
      key: "payment_frequensy",
      width: "10%",
    },
    {
      title: "Package Name",
      dataIndex: "package_name",
      key: "package_name",
      width: "15%",
    },
    {
      title: "Bank",
      dataIndex: "bank_name",
      key: "bank_name",
      width: "15%",
    },
    {
      title: "Amount",
      dataIndex: "order_total_amount",
      key: "order_total_amount",
      width: "20%",
    },
    {
      title: "",
      dataIndex: "status",
      key: "",
    },
    {
      title: "",
      dataIndex: "view",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);
  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  // ========================= Fetch eNach List ================================

  const fetchData = async (page, limit, search) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);

      const response = await axios.post(
        "https://skdm.in/server/manage/enach-details.php?action=fetch&page=" +
          (page - 1) +
          "&limit=" +
          limit +
          "&search=" +
          search +
          "",
        formData
      );
      if (response.data) {
        setLoading(false);
        const updatedData = response.data.list.map((item, i) => ({
          ...item,
          start_date: moment(item.start_date).format("DD MMM YYYY"),
          end_date: moment(item.end_date).format("DD MMM YYYY"),
          order_total_amount: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <PiCurrencyInrBold />
              &nbsp;{item.order_total_amount}
            </div>
          ),
          status: (
            <Tooltip title="View Status" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => checkStatus(item.enach_api_id, i)}
              >
                Check Status
              </Button>
            </Tooltip>
          ),

          view: (
            <Tooltip title="View eNach Details" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerView(item)}
              >
                View
              </Button>
            </Tooltip>
          ),
        }));
        setFetchList(updatedData);
        setTotalData(response.data.total);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  // ======================= Handle Drawer Add =================================

  const handleDrawerView = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "viewEnach",
      title: "eNach Details",
      value: value,
    });
  };

  // ======================= Handle Drawer Add =================================

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "enachCreate",
      title: "eNach Add",
      ID: null,
    });
  };

  // =============================== Check Status =======================
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enachStatus, setEnachStatus] = useState(null);

  const checkStatus = async (ID) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("mandate_id", ID);
    formData.append("action", "status");

    const response = await axios.post(
      "https://skdm.in/server/manage/enach-details.php",
      formData
    );

    if (response.data.status === "success") {
      setLoading(false);
      setEnachStatus(response.data.mandate_status);
      setIsModalOpen(true);
    } else {
      setLoading(false);
      message.error("Uable to find please try again");
    }
  };

  const currentStatus = () => {
    if (enachStatus === "Draft") {
      return 0;
    } else if (enachStatus === "Invited") {
      return 1;
    } else if (enachStatus === "Invitation_Accepted") {
      return 2;
    } else if (enachStatus === "Initiated") {
      return 3;
    } else if (enachStatus === "Registered") {
      return 4;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <TitleComp
        name="eNach Lists"
        handleDrawerAdd={handleDrawerAdd}
        totalData={10}
        type="eNach"
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
      <Modal
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <OkBtn />
          </>
        )}
      >
        <Steps
          progressDot
          current={currentStatus()}
          direction="vertical"
          items={[
            {
              title: "Draft",
              description: "your request is now in draft.",
            },
            {
              title: "Invitation Sent",
              description:
                "user verification and invitaion link send to given number and email.",
            },
            {
              title: "Invite Accepted",
              description:
                "Invitation accepted by client pending for upload debit card details.",
            },
            {
              title: "Initiated",
              description: "Debit card details uploaded and intiated eNach.",
            },
            {
              title: "Registered",
              description: "eNach Registerd now can take payment.",
            },
          ]}
        />
      </Modal>
    </div>
  );
}
