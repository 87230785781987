import React from "react";
import { Button, Layout, Menu } from "antd";
import { useSideBar } from "../../context/nav-toggle";
import {
  BiListUl,
  BiLogOut,
  BiSolidDashboard,
  BiSolidOffer,
} from "react-icons/bi";
import { FaUsersCog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MenuItem from "antd/es/menu/MenuItem";
import { BsPersonWorkspace } from "react-icons/bs";
import { MdAccountCircle, MdOutlinePayment } from "react-icons/md";
import { getProfile, removeToken } from "../../services/localStorageServices";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { IoQrCodeSharp } from "react-icons/io5";
import { FaMicroblog } from "react-icons/fa";

const { Sider } = Layout;


export default function Sidebar() {
  const { isOpen } = useSideBar();
  const { profile } = getProfile();
  const navigate = useNavigate();

  const navigateToPage = (url) => {
    navigate(url);
  };

  const logout = () => {
    removeToken();
    navigate("/login");
  };
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        left: 0,
        top: 0,
        bottom: 0,
      }}
      trigger={null}
      collapsible
      collapsed={isOpen}
    >
      <div className="demo-logo-vertical">SKDM Admin</div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
        <MenuItem
          key="1"
          icon={<BiSolidDashboard />}
          onClick={() => navigateToPage("/dashboard")}
        >
          Dashboard
        </MenuItem>
        {profile.type === "master" ? (
          <MenuItem
            key="2"
            icon={<MdAccountCircle />}
            onClick={() => navigateToPage("/account")}
          >
            Accounts
          </MenuItem>
        ) : null}
        <MenuItem
          key="3"
          icon={<BsPersonWorkspace />}
          onClick={() => navigateToPage("/employees")}
        >
          Emplyees
        </MenuItem>
        <MenuItem
          key="4"
          icon={<FaUsersCog />}
          onClick={() => navigateToPage("/clients")}
        >
          Clients
        </MenuItem>
        <MenuItem
          key="5"
          icon={<BiSolidOffer />}
          onClick={() => navigateToPage("/packages")}
        >
          Packages
        </MenuItem>
        <MenuItem
          key="6"
          icon={<MdOutlineMiscellaneousServices />}
          onClick={() => navigateToPage("/services")}
        >
          Services
        </MenuItem>
        <MenuItem
          key="7"
          icon={<IoQrCodeSharp />}
          onClick={() => navigateToPage("/qr-code")}
        >
          QR Code
        </MenuItem>

        <MenuItem
          key="8"
          icon={<FaMicroblog />}
          onClick={() => navigateToPage("/Blog")}
        >
        Blog
        </MenuItem>
        {/* <MenuItem
          key="8"
          icon={<BiListUl />}
          onClick={() => navigateToPage("/bookings")}
        >
          Bookings
        </MenuItem>
        <MenuItem
          key="9"
          icon={<MdOutlinePayment />}
          onClick={() => navigateToPage("/enach")}
        >
          eNach
        </MenuItem> */}
        <MenuItem
          key="9"
          // icon={<BiListUl />}
          // onClick={() => navigateToPage("/bookings")}
        >
          <Button onClick={logout} className="logoutBtn">
            <BiLogOut />
            &nbsp;Log Out
          </Button>
        </MenuItem>
      </Menu>
    </Sider>
  );
}
