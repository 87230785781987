import React, { useEffect, useState } from "react";
import TitleComp from "../components/Title/title";
import { Button, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import TableComp from "../components/table/tables";
import { useDrawer } from "../context/drawer";
import DrawerComp from "../components/Drower/Drawer";
import { useSelector } from "react-redux";

export default function BookingList() {
  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: "10%",
    },
    {
      title: "Package Name",
      dataIndex: "package_name",
      key: "package_name",
      width: "15%",
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      width: "15%",
    },
    {
      title: "Amount",
      dataIndex: "order_total_amount",
      key: "order_total_amount",
      width: "10%",
    },
    {
      title: "Year",
      dataIndex: "order_year",
      key: "order_year",
      width: "5%",
    },
    {
      title: "Type",
      dataIndex: "order_type",
      key: "order_type",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "order_status",
      key: "order_status",
      width: "10%",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
    },
    {
      title: "",
      dataIndex: "view",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(0);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);

  // =========================================== Drawer Context =========================

  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit, search) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("token", token);
      const response = await axios.post(
        "https://skdm.in/server/manage/booking-details.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          ""
      ,formData);
      if (response.data) {
        setLoading(false);
        setTotalData(response.data.total);
        const updatedData = response.data.list.map((item) => ({
          ...item,
          created_at: moment(item.created_at).format("DD MMM YYYY"),

          view: (
            <Tooltip title="View Booking Details" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerView(item)}
              >
                View
              </Button>
            </Tooltip>
          ),
        }));
        setFetchList(updatedData);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };
  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  // ======================= Handle Drawer View =================================

  const handleDrawerView = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "bookingView",
      title: "Booking Details",
      value: value,
    });
  };

  const handleDrawerAdd = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "bookingAdd",
      title: "Make Booking",
      value: value,
    });
  };
  return (
    <div>
      <TitleComp
        name="Booking List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={totalData}
        type="Bookings"
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
