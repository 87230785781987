import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
import { useDrawer } from "../context/drawer";
import { Badge, Button, Popover, Tooltip, message } from "antd";
import { PiCurrencyInrBold } from "react-icons/pi";
import DrawerComp from "../components/Drower/Drawer";
import { useSelector } from "react-redux";
import { get, post } from "../services/userAuthAPI";
import { MdEdit } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";

export default function PackageList() {
  const columns = [
    {
      title: "Name",
      dataIndex: "package_name",
      key: "package_name",
      width: "20%",
    },
    {
      title: "Price",
      dataIndex: "package_price",
      key: "package_price",
      width: "10%",
    },
    {
      title: "Year",
      dataIndex: "package_year",
      key: "package_year",
      width: "10%",
    },
    {
      title: "Discount",
      dataIndex: "package_discount",
      key: "package_discount",
      width: "5%",
    },
    {
      title: "Type",
      dataIndex: "package_type",
      key: "package_type",
      width: "10%",
    },
    {
      title: "Summary",
      dataIndex: "package_desc",
      key: "package_desc",
      width: "20%",
    },
    {
      title: "Services",
      dataIndex: "sevices",
      key: "sevices",
    },

    {
      title: "",
      dataIndex: "action",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(0);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);

  // =========================================== Drawer Context =========================

  const { drawerData, setDrawerData } = useDrawer();

  // ========================================== Fetch Pakage Data ========================
  function formatIndianNumber(number) {
    return number.toLocaleString("en-IN", {
      style: "decimal",
      maximumFractionDigits: 0,
    });
  }
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit, search) => {
    try {
      setLoading(true);
      const response = await get(
        `package.php?action=fetch&page=${page}&limit=${limit}&token=${token}${
          search !== "" ? "&search=" + search : ""
        }`
      );
      if (response) {
        setLoading(false);
        setTotalData(response.total);
        const updatedData = response.list.map((item) => ({
          ...item,
          package_discount: <>{item.package_discount}&nbsp;%</>,
          package_price: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <PiCurrencyInrBold />
              &nbsp;{formatIndianNumber(parseInt(item.package_price))}
            </div>
          ),
          package_year: <>{item.package_year}&nbsp;Year</>,
          sevices: (
            <>
              <Badge showZero color="#f5222d">
                {item.services.length > 0 ? (
                  <Popover
                    content={
                      <ul style={{ listStyle: "none" }}>
                        {item.services.map((value) => (
                          <li
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <strong>{value.service_name}</strong>
                            <span>
                              <PiCurrencyInrBold />
                              {value.price}
                            </span>
                          </li>
                        ))}
                      </ul>
                    }
                    title="Service List"
                  >
                    <Button
                      style={{
                        background: "orange",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.services.length}{" "}
                      <FaAngleDown style={{ marginLeft: "5px" }} />
                    </Button>
                  </Popover>
                ) : (
                  0
                )}
              </Badge>
            </>
          ),
          action: (
            <>
              {" "}
              <Tooltip title="View Client Details" placement="bottom">
                <Button
                  className="otherButtons"
                  onClick={() => handleDrawerUpdate(item)}
                >
                  <MdEdit />
                </Button>
              </Tooltip>{" "}
              <Tooltip title="View Client Details" placement="bottom">
                <Button
                  className="removeButton"
                  onClick={() => handleUptadeActive(item.package_id)}
                >
                  <FaTrashAlt />
                </Button>
              </Tooltip>
            </>
          ),
        }));
        setFetchList(updatedData);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  // ======================= Handle Drawer Update =================================

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "packageUpdate",
      title: "Package Update",
      value: value,
    });
  };

  // ======================= Handle Drawer Add =================================

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "packageAdd",
      title: "Package Add",
      ID: null,
    });
  };

  // ====================================== Update Active Status ===========================
  const handleUptadeActive = async (ID) => {
    try {
      if (window.confirm("Are sure you want remove package")) {
        const formData = new FormData();
        formData.append("package_id", ID);
        formData.append("is_active", 0);
        formData.append("action", "update");
        formData.append("token", token);

        const response = await post("package.php", formData);
        if (response.data) {
          message.success("Status Update!");
        }
      }
    } catch (error) {
      message.error("Status Update Failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };
  return (
    <div>
      <TitleComp
        name="Packages List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={totalData}
        type="Packages"
        isShowButton={true}
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
