import React, { useState, useEffect } from "react";
import { Button, Upload, message, Space, Form, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { post } from "../../../services/userAuthAPI"; // Assume you have a post function to handle API requests
import "react-quill/dist/quill.snow.css";

// ================= Image Validation ==================
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 < 150;
  if (!isLt2M) {
    message.error("Image must be smaller than 1MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function UpdateBlog({ blogId }) {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [existingBlog, setExistingBlog] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    // Fetch blog data by blogId and populate the form
    const fetchBlogData = async () => {
      try {
        const response = await post("https://shreekrishnadigital.com/api/get_blog.php", { blogId });
        if (response.status === "success") {
          setExistingBlog(response.data);
          form.setFieldsValue({
            title: response.data.blog_title,
            description: response.data.blog_desc,
          });
          if (response.data.blog_img) {
            setFileList([
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: response.data.blog_img, // image URL from the server
              },
            ]);
          }
        } else {
          message.error("Failed to load blog data!");
        }
      } catch (error) {
        console.error("Error fetching blog data", error);
        message.error("Error loading the blog!");
      }
    };

    fetchBlogData();
  }, [blogId, form]);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("action", "update");
      formData.append("blog_id", blogId); // Add blog ID to update the correct blog
      formData.append("blog_title", values.title);
      formData.append("blog_desc", values.description);

      if (fileList && fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("blog_img", fileList[0].originFileObj);
      }

      setLoading(true);

      const response = await post("https://shreekrishnadigital.com/api/blog.php", formData);

      if (response) {
        setLoading(false);
        if (response.status === "success") {
          message.success("Blog updated successfully!");
          form.resetFields();
        } else {
          message.error("Failed to update blog!");
        }
      }
    } catch (error) {
      setLoading(false);
      message.error("An error occurred while updating the blog!");
      console.error("Error making POST request", error);
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const handleChange = (file) => setFileList(file.fileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Upload Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="upload"
        >
          <Upload
            listType="picture-circle"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            accept=".jpeg, .jpg, .png"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          name="title"
          label="Blog Title"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input the blog title!",
            },
          ]}
        >
          <Input placeholder="Enter blog title" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Blog Description"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input the blog description!",
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Enter blog description" />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={() => form.resetFields()}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
