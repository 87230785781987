import React, { useState } from "react";
import { message, Steps } from "antd";
import PackageSelect from "./packageSelect";
import BankInfo from "./bankInfo";
import ClientInfo from "./clientInfo";
import moment from "moment";
import axios from "axios";
import { useDrawer } from "../../../context/drawer";

const steps = [
  {
    title: "Packages",
    content: "First-content",
  },
  {
    title: "Client",
    content: "Second-content",
  },
  {
    title: "Bank",
    content: "Last-content",
  },
];

export default function AddEnachForm() {
  const { toggleDrawer } = useDrawer();

  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [packageValue, setPackageValue] = useState([]);
  const [clientValue, setClientValue] = useState([]);
  const [bankValue, setBankValue] = useState([]);
  const [enachDetail, setEnachDetail] = useState({
    first_collection_date: "",
    final_collection_date: "",
    due_date: "",
    frequency: "",
  });

  const [responseError, setResponseError] = useState(null);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  function sumPrices(priceArray) {
    // Initialize a variable to store the sum
    let total = 0;

    // Iterate through the array and add up the values
    for (let i = 0; i < priceArray.length; i++) {
      total += parseFloat(priceArray[i].package_price);
    }

    return total;
  }


  const handleSubmitData = async (startDate, finalDate, frequency) => {
    try {
      setLoading(true);

        // ===================== Order Api Call ==================
        const formDataOrder = new FormData();

        formDataOrder.append("debtor_name", clientValue[0].client_name);
        formDataOrder.append("email_address",clientValue[0].client_email);
        formDataOrder.append("mobile_number", clientValue[0].client_phone)
        formDataOrder.append("debtor_account_id", bankValue[0].bank_account_no);
        formDataOrder.append("debtor_account_type", bankValue[0].bank_type);
        formDataOrder.append("amount", sumPrices(packageValue));
        formDataOrder.append("package_id", packageValue[0].package_id);
        formDataOrder.append("occurance_frequency_type", frequency);
        formDataOrder.append("first_collection_date", moment(startDate).format("YYYY-MM-DD"));
        formDataOrder.append("final_collection_date", moment(finalDate).format("YYYY-MM-DD"));
        formDataOrder.append("instructed_agent_id_type", bankValue[0].branch_code_type);
        formDataOrder.append("instructed_agent_id", bankValue[0].bank_branch_code);
        formDataOrder.append("instructed_agent_code", bankValue[0].bank_code);
        formDataOrder.append("client_id", clientValue[0].client_id);
        formDataOrder.append("emp_id", clientValue[0].emp_id);
        formDataOrder.append("order_year", packageValue[0].package_year);
        formDataOrder.append("order_type", "eNach");
        formDataOrder.append("action", "add");

        const orderResponse = await axios.post(
          "https://skdm.in/server/manage/order-detail.php",
          formDataOrder
        );
        if (orderResponse.data.status === "success") {
          // ================= eNach Api Call ===================

          const orderId = orderResponse.data.orderId;
          const enach_api_id = orderResponse.data.emandate_id;
          const refrence_id = orderResponse.data.reference_id;
          const formDataEnach = new FormData();

          formDataEnach.append("client_id", clientValue[0].client_id);
          formDataEnach.append("order_id", orderId);
          formDataEnach.append("package_id", packageValue[0].package_id)
          formDataEnach.append("bank_info_id", bankValue[0].bank_id);
          formDataEnach.append("start_date", moment(startDate).format("YYYY-MM-DD"))
          formDataEnach.append("end_date", moment(finalDate).format("YYYY-MM-DD"))
          formDataEnach.append("due_date", finalDate.getDay());
          formDataEnach.append("enach_api_id", enach_api_id);
          formDataEnach.append("refrence_id", refrence_id);
          formDataEnach.append("payment_frequensy", frequency);
          formDataEnach.append("action", "add");

          const reponseEnach = await axios.post(
            "https://skdm.in/server/manage/enach-details.php",
            formDataEnach
          );

          if (reponseEnach.data) {
            toggleDrawer();
            setLoading(false);
            message.success("Submited Successfull");
            setClientValue([]);
            setBankValue([]);
            setPackageValue([]);
            setEnachDetail([]);
            setResponseError(null)
            setCurrent(0);

            return true;
          }
        }

      if (orderResponse.data.status === "failed") {
        setLoading(false);
        setResponseError(orderResponse.data.error_description);
        message.error(orderResponse.data.error_description);
        return true;
      }
    } catch (error) {
      setLoading(false);
      message.error("Failed to submit");
      console.error("Error:", error);

      return true;

    }
  };

  return (
    <div>
      <Steps current={current} items={items} />
      {current === 0 ? (
        <PackageSelect
          packageValue={packageValue}
          setPackageValue={setPackageValue}
          next={next}
        />
      ) : null}
      {current === 1 ? (
        <ClientInfo
          prev={prev}
          next={next}
          clientValue={clientValue}
          setClientValue={setClientValue}
          bankValue={bankValue}
          setBankValue={setBankValue}
        />
      ) : null}
      {current === 2 ? (
        <BankInfo
          loading={loading}
          prev={prev}
          next={next}
          enachDetail={enachDetail}
          setEnachDetail={setEnachDetail}
          handleSubmitData={handleSubmitData}
          
        />
      ) : null}

      {responseError ? <span style={{color:"red"}}>{responseError}</span> : null}
    </div>
  );
}
