import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Empty, Select } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import serviceList from "../../JSON/services-list.json";
import { PiCurrencyInrBold } from "react-icons/pi";
import { useSelector } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";
import { post } from "../../../services/userAuthAPI";
import TextArea from "antd/es/input/TextArea";
import { FaCheck } from "react-icons/fa6";

export default function PackageAddForm() {
  const { toggleDrawer, drawerData } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [packageServiceList, setPackageServiceList] = useState([]);
  const refForm = useRef(null);

  // ========================= Select Functionalty ==========================

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);



  const handleAddServices = () => {
    const data = {
      service_name: "",
      service_desc: "",
      price: "",
      type: "",
    };

    setPackageServiceList((prev) => [...prev, data]);
  };

  useEffect(() => {
    if (drawerData.value !== null) {
      form.setFieldsValue({
        id: drawerData.value.package_id,
        name: drawerData.value.package_name,
        price: parseInt(drawerData.value.package_price),
        year: drawerData.value.package_year,
        discount: drawerData.value.package_discount,
        type: drawerData.value.package_type,
        summary: drawerData.value.package_desc,
      });
    }
    setPackageServiceList(drawerData.value.services);
    // eslint-disable-next-line
  }, [drawerData]);


  const handleUpdateService = async (data) => {
    try {
      const formData = new FormData();

      formData.append("service_id", data.service_id);
      formData.append("service_name", data.service_name);
      formData.append("service_desc", data.service_desc);
      formData.append("price", data.price);
      formData.append("action", "updateService");
      formData.append("token", token);

      const resp = await post("package.php", formData);

      if (resp) {
        setLoading(false);
        message.success("Update success!");
      }
    } catch (error) {
      setLoading(false);
      message.error("update failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const handleRemoveService = async ($id, index) => {
    try {
      if (window.confirm("Are sure you want to remove service")) {
        const formData = new FormData();

        formData.append("service_id", $id);
        formData.append("action", "removeService");
        formData.append("token", token);

        const resp = await post("package.php", formData);

        if (resp) {
          setLoading(false);
          message.success("Removed success!");
          const prevData = [...packageServiceList]; // Create a shallow copy
          prevData.splice(index, 1); // Remove one item at the specified index
          setPackageServiceList(prevData);
        }
      }
    } catch (error) {
      setLoading(false);
      message.error("update failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const handleUpdateDetail = async() => {
    try {
      const values = refForm.current.getFieldsValue();
      const formData = new FormData();
      formData.append("package_id", values.id);
      formData.append("package_name", values.name);
      formData.append("slug", values.name.toLowerCase().split(" ").join("-"));
      formData.append("package_price", values.price);
      formData.append("package_year", values.year);
      formData.append("package_discount", values.discount);
      formData.append("package_desc", values.summary);
      formData.append("action", "update");
      formData.append("token", token);

      setLoading(true);
      const response = await post("package.php", formData);
      console.log(response)
      if (response) {
        setLoading(false);
        form.resetFields();
        toggleDrawer();
        message.success("Submit success!");
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        ref={refForm}
      >
        <Form.Item
          name="id"
          label="Package Name"
          style={{
            margin: "3px 4px",
            display: "none",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Package Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label="Package Price"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input addonBefore={<PiCurrencyInrBold />} />
        </Form.Item>
        <Form.Item
          name="year"
          label="Package Year"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input addonAfter="Year" />
        </Form.Item>
        <Form.Item
          name="discount"
          label="Package Discount"
          hasFeedback
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input addonAfter="%" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Package Type"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Service"
            options={serviceList}
          />
        </Form.Item>
        <Form.Item
          name="summary"
          label="Package Summary"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea placeholder="Write here.." showCount maxLength={250} />
        </Form.Item>
      </Form>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Divider
          orientation="left"
          style={{ maxWidth: "100px", minWidth: "70%" }}
        >
          Service List{" "}
        </Divider>
        <Button
          onClick={handleAddServices}
          style={{ margin: "0 10px", background: "#000", color: "#fff" }}
        >
          Add Service
        </Button>
      </div>
      {packageServiceList?.length > 0 ? (
        packageServiceList.map((value, index) => (
          <div
            style={{
              borderBottom: "1px solid #afafaf",
              marginBottom: "15px",
              paddingBottom: "15px",
            }}
          >
            <div
              className="mainHeader"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Input
                value={value.service_name}
                placeholder="Service Name"
                style={{ marginBottom: "10px", marginRight: "10px" }}
                onChange={(e) =>
                  setPackageServiceList((prev) => {
                    const newList = [...prev];
                    newList[index] = {
                      ...newList[index],
                      service_name: e.target.value,
                    };
                    return newList;
                  })
                }
              />

              <Input
                value={value.price}
                placeholder="Service Price"
                style={{ marginBottom: "10px", marginRight: "10px" }}
                prefix={<PiCurrencyInrBold />}
                onChange={(e) =>
                  setPackageServiceList((prev) => {
                    const newList = [...prev];
                    newList[index] = {
                      ...newList[index],
                      price: e.target.value,
                    };
                    return newList;
                  })
                }
              />

              <Button
                style={{
                  marginBottom: "10px",
                  marginRight: "10px",
                  background: "green",
                  color: "#fff",
                }}
                onClick={() => handleUpdateService(value)}
              >
                <FaCheck />
              </Button>
              <Button
                danger
                style={{ marginBottom: "10px" }}
                onClick={() => handleRemoveService(value.service_id, index)}
              >
                <FaTrashAlt />
              </Button>
            </div>
            <ReactQuill
              theme="snow"
              value={value.service_desc}
              onChange={(value) =>
                setPackageServiceList((prev) => {
                  const newList = [...prev];
                  newList[index] = { ...newList[index], service_desc: value };
                  return newList;
                })
              }
            />
          </div>
        ))
      ) : (
        <Empty />
      )}

      <Space style={{ marginTop: "10px" }}>
        <Button type="primary" loading={loading} onClick={handleUpdateDetail}>
          Submit
        </Button>
        <Button danger onClick={toggleDrawer}>
          Cancel
        </Button>
      </Space>
    </div>
  );
}
