import React, { useEffect, useState } from "react";
import { Button, Divider, Select, Switch } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import { useSelector } from "react-redux";
import { get, post } from "../../../services/userAuthAPI";

export default function AddForm() {
  const { toggleDrawer } = useDrawer();
  const [createdBy, setCreatedBy] = useState("Self");
  const [loading, setLoading] = useState(false);
  const [emplList, setEmpList] = useState();
  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("client_name", values.name);
      formData.append("client_phone", values.phone);
      formData.append("client_email", values.email);
      formData.append("client_address", values.address);
      formData.append("client_business_name", values.business_name);
      formData.append("client_business_type", values.business_type);
      formData.append("client_gst_number", values.gst_number);
      formData.append("created_by", values.created_by);
      formData.append("client_status", "active");
      formData.append("work_status", values.status);
      formData.append("action", "add");
      if (values.created_by !== "Self") {
        formData.append("account_id", values.emp_id);
      }
      formData.append("slug", values.name.toLowerCase().split(" ").join("-"));
      formData.append("token", token);

      setLoading(true);
      const response = await post(
        "client.php",
        formData
      );
      if (response) {
        setLoading(false);
        if (response.status === "Already have client") {
          message.error("Number Already Registered!");
        } else {
          form.resetFields();
          toggleDrawer();
          message.success("Submit success!");
        }
      }
    } catch (error) {
      setLoading(false);
      message.error("Submit failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleCreateBy = (value) => {
    setCreatedBy(value);
  };

  // ========================= Empl List ====================

  const fetchData = async (page, limit, search) => {
    try {
      const response = await get(
        `account.php?action=fetch&page=${page}&limit=${limit}&token=${token}${
          search !== "" ? "&search=" + search : ""
        }`
      );
      if (response) {
        setEmpList(response.list);
      }
    } catch (error) {
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(0, 10, "");
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        name="name"
        label="Client Name"
        style={{
          margin: "3px 4px",
        }}
        hasFeedback
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Client Number"
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "3px 4px",
        }}
        hasFeedback
        rules={[
          {
            required: true,
          },
          { max: 10 },
          { min: 10 },
        ]}
      >
        <Input type="number" addonBefore="+91" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Client Email"
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "3px 4px",
        }}
        hasFeedback
        rules={[
          {
            required: true,
          },
          { type: "email", warningOnly: true },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="address"
        label="Client Address"
        hasFeedback
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Divider orientation="left"> Business Info</Divider>
      <Form.Item
        name="business_name"
        label="Buiness Name"
        style={{ margin: "3px 4px" }}
        hasFeedback
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="business_type"
        label="Business Type"
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "3px 4px",
        }}
        hasFeedback
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="gst_number"
        label="GST Number"
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "3px 4px",
        }}
        hasFeedback
      >
        <Input />
      </Form.Item>
      <Divider orientation="left">Activation Satatus</Divider>
      <Form.Item
        name="status"
        label="Client Status"
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "3px 4px",
        }}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Deactive"
          defaultChecked="Active"
        />
      </Form.Item>
      <Form.Item
        name="created_by"
        label="Created By"
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "3px 4px",
        }}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please select created by!",
          },
        ]}
      >
        <Select
          style={{ width: 150 }}
          onChange={handleCreateBy}
          placeholder="Please select created by"
          options={[
            { value: "Self", label: "Self" },
            { value: "Agent", label: "Agent" },
          ]}
        />
      </Form.Item>
      <Form.Item name="emp_id" label="Select Agent">
        <Select
          showSearch
          disabled={createdBy === "Self" ? "true" : false}
          placeholder="Select a person"
          optionFilterProp="children"
          onSearch={(value) => fetchData(0, 10, value)}
          filterOption={filterOption}
        >
          {emplList && emplList.length > 0
            ? emplList.map((valueEmp) => (
                <Select.Option
                  value={valueEmp.account_id}
                  label={valueEmp.account_name}
                >
                  {valueEmp.account_name}
                </Select.Option>
              ))
            : null}
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" loading={loading} htmlType="submit">
            Submit
          </Button>
          <Button danger onClick={toggleDrawer}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
