import React, { useEffect, useState } from "react";
import { Button, Select, Upload } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import "react-quill/dist/quill.snow.css";
import employeeList from "../../JSON/employee-list.json";
import { PlusOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";

import { post } from "../../../services/userAuthAPI";
import { useSelector } from "react-redux";

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 < 150;
  if (!isLt2M) {
    message.error("Image must smaller than 1MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function UpdateEmployee() {
  const { drawerData, toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([
    {
      uid: "0",
      name: "no image",
      status: "done",
      url: "",
      thumbUrl: "",
    },
  ]);
  const [updateImage, setUpdateImage] = useState(false);

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string, value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  //   ============================== Call Update API ========================
  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("account_id", values.ID);
      formData.append("account_name", values.name);
      formData.append("account_email", values.email);
      formData.append("account_number", values.phone);
      if (updateImage) {
        formData.append("account_image", fileList[0].originFileObj);
      }
      formData.append("account_role", values.role);
      if (values.password) {
        formData.append("password", values.password);
      }
      formData.append("username", values.username);
      formData.append("slug", values.name.toLowerCase().split(" ").join("-"));
      formData.append("action", "update");
      formData.append("token", token);

      setLoading(true);

      const response = await post("account.php", formData);
      if (response) {
        setLoading(false);
        form.resetFields();
        toggleDrawer();
        message.success("Submit success!");
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  //   ====================== Image Upload =======================
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setUpdateImage(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  //   =================== File Check =================
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  //   ====================== Data Set ===================

  useEffect(() => {
    if (drawerData.value !== null) {
      form.setFieldsValue({
        ID: drawerData.value.account_id,
        Upload: drawerData.value.account_image,
        name: drawerData.value.account_name,
        phone: drawerData.value.account_number,
        email: drawerData.value.account_email,
        role: drawerData.value.account_role,
        username: drawerData.value.username,
        password: drawerData.value.password,
        confirm_password: drawerData.value.password,
      });

      fileList[0].url = drawerData.value.emp_img;
      fileList[0].thumbUrl = drawerData.value.emp_img;
      setFileList([...fileList]);
    }
    // eslint-disable-next-line
  }, [drawerData]);

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Upload Profile"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="upload"
        >
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-circle"
            defaultFileList={[...fileList]}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            accept=".jpeg, .jpg, .png"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item
          name="ID"
          label="Eployee ID"
          style={{
            display: "none",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
        >
          <Input disabled placeholder="Employee ID" />
        </Form.Item>

        <Form.Item
          name="name"
          label="Eployee Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Employee Phone"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            { min: 10 },
            { max: 10 },
          ]}
        >
          <Input type="number" placeholder="Phone" addonBefore="+ 91" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email Year"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="role"
          label="Employee Role"
          hasFeedback
          style={{
            margin: "3px 4px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Service"
            optionFilterProp="children"
            filterOption={filterOption}
            options={employeeList}
          />
        </Form.Item>
        <Form.Item
          name="username"
          label="Username"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            { min: 6 },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
        >
          <Input.Password
            placeholder="Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          label="Confirm Password"
          dependencies={["password"]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
