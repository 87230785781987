import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
import { useDrawer } from "../context/drawer";
import { Avatar, Badge, Button, Popconfirm, Tooltip, message } from "antd";
import DrawerComp from "../components/Drower/Drawer";
import moment from "moment";
import { FaTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { get, post } from "../services/userAuthAPI";
import { MdEdit } from "react-icons/md";
import { FaList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function PackageList() {
  const columns = [
    {
      title: "Profile",
      dataIndex: "account_image",
      key: "account_image",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "account_name",
      key: "account_name",
      width: "15%",
    },
    {
      title: "Number",
      dataIndex: "account_number",
      key: "account_number",
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "account_email",
      key: "account_email",
      width: "15%",
    },
    {
      title: "Role",
      dataIndex: "account_role",
      key: "account_role",
      width: "10%",
    },
    {
      title: "Update At",
      dataIndex: "create_at",
      key: "create_at",
    },
    {
      title: "Today Calls",
      dataIndex: "leads",
      key: "leads",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);
  const navigate = useNavigate();

  // =========================================== Drawer Context =========================

  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  // ========================================== Fetch Pakage Data ========================
  const fetchData = async (page, limit, search) => {
    try {
      setLoading(true);
      const response = await get(
        `account.php?action=fetch&page=${page}&limit=${limit}&token=${token}${
          search !== "" ? "&search=" + search : ""
        }`
      );
      if (response) {
        setLoading(false);
        setTotalData(response.total);
        const updatedData = response.list.map((item) => ({
          ...item,
          leads: (
            <>
              <Badge count={item.todayLeads} showZero color="#faad14" />
            </>
          ),
          account_image: (
            <Avatar src={item.account_image}>
              {item.account_name.charAt(0).toUpperCase()}
            </Avatar>
          ),
          create_at: moment(new Date(item.created_at)).format("DD MMM YYYY"),
          action: (
            <>
              <Tooltip title="View Employee Details" placement="bottom">
                <Button
                  className="otherButtons"
                  onClick={() => handleDrawerUpdate(item)}
                >
                  <MdEdit />
                </Button>
              </Tooltip>
              <Tooltip title="View Leads" placement="bottom">
                <Button
                  style={{ background: "orange", margin: "0 10px" }}
                  onClick={() => navigate("/leads/" + item.account_id)}
                >
                  <FaList color="#fff" />
                </Button>
              </Tooltip>
              <Popconfirm
                title="Remove Employee"
                description="Are you sure to remove this employee?"
                onConfirm={() => confirm(item.account_id)}
                okText="Yes"
                cancelText="No"
              >
                {" "}
                <Button danger>
                  {" "}
                  <FaTrashAlt />
                </Button>
              </Popconfirm>
            </>
          ),
        }));
        setFetchList(updatedData);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  // ======================= Handle Drawer Update =================================

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "employeeUpdate",
      title: "Employee Update",
      value: value,
    });
  };

  // ======================= Handle Drawer Add =================================

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "employeeAdd",
      title: "Employee Add",
      ID: null,
    });
  };

  // ===================== Remove Employee =====================
  const confirm = async (ID) => {
    try {
      const formData = new FormData();
      formData.append("account_id", ID);
      formData.append("is_active", 0);
      formData.append("action", "update");
      formData.append("token", token);

      const response = await post("account.php", formData);
      if (response.data) {
        message.success("Removed successfully");
      }
    } catch (error) {
      message.error("Status Update Failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };
  return (
    <div>
      <TitleComp
        name="Employee List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={totalData}
        type="Employees"
        isShowButton={true}
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
