import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import { Form, Input, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PiCurrencyInrBold } from "react-icons/pi";
import { useSelector } from "react-redux";
import { post } from "../../../services/userAuthAPI";

export default function UpdateService() {
  const { toggleDrawer, drawerData } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [serviceSummary, setServiceSummary] = useState(null);
  const formRef = useRef();

  // ========================= Select Functionalty ==========================

  useEffect(() => {
    if (drawerData.value !== null) {
      form.setFieldsValue({
        id: drawerData.value.service_id,
        name: drawerData.value.service_name,
        price: parseInt(drawerData.value.price),
      });
      setServiceSummary(drawerData.value.service_desc);
    }

    // eslint-disable-next-line
  }, [drawerData]);

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const handleUpdateService = async () => {
    try {
      const values = formRef.current.getFieldsValue();

      const formData = new FormData();
      formData.append("service_id", values.id);
      formData.append("service_name", values.name);
      formData.append("service_desc", serviceSummary);
      formData.append("price", values.price);
      formData.append("action", "updateService");
      formData.append("token", token);

      setLoading(true);
      const response = await post("package.php", formData);
      if (response) {
        setLoading(false);
        form.resetFields();
        toggleDrawer();
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  return (
    <div className="drawerForm">
      <Form ref={formRef} form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name="id"
          label="Service Id"
          style={{
            margin: "3px 4px",
            display: "none",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Service Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label="Service Price"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input addonBefore={<PiCurrencyInrBold />} />
        </Form.Item>

        <ReactQuill
          theme="snow"
          style={{ margin: "10px 0 0 0" }}
          value={serviceSummary}
          onChange={(value) => setServiceSummary(value)}
        />

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button
              type="primary"
              loading={loading}
              onClick={handleUpdateService}
            >
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
