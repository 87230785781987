import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
import axios from "axios";
import { Avatar, Button, Switch, Tooltip, message } from "antd";
import DrawerComp from "../components/Drower/Drawer";
import { useDrawer } from "../context/drawer";
import { useSelector } from "react-redux";
import moment from "moment";

export default function AccountDetails() {
  const columns = [
    {
      title: "Account ID",
      dataIndex: "account_id",
      key: "account_id",
      width: "15%",
    },
    {
      title: "Profile",
      dataIndex: "account_image",
      key: "account_image",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "account_name",
      key: "account_name",
      width: "25%",
    },

    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: "15%",
    },
    {
      title: "Account Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      width: "15%",
    },
    {
      title: "Login Status",
      dataIndex: "login_status",
      key: "login_status",
      width: "10%",
    },
    {
      title: "Account Status",
      dataIndex: "account_status",
      key: "account_status",
      width: "10%",
    },

    {
      title: "",
      dataIndex: "view",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(0);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);

  // =========================================== Drawer Context =========================
  const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit, search) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);
      const response = await axios.post(
        "https://skdm.in/server/manage/admin-login.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          "",
        formData
      );
      if (response.data) {
        setLoading(false);
        const updatedData = response.data.list.map((item) => ({
          ...item,
          account_image: (
            <Avatar
              style={{ backgroundColor: randomColor }}
              src={item.account_image !== null ? item.account_image : null}
              alt={item.account_name}
            >
              {item.account_name.charAt(0).toUpperCase()}
            </Avatar>
          ),
          account_status: (
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Deactive"
              onChange={(value) => handleUptadeActive(value, item.account_id)}
              defaultChecked={item.account_status === "true"}
            />
          ),
          updated_at: moment(item.updatedData).format("llll"),
          view: (
            <Tooltip title="View Account Details" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerUpdate(item)}
              >
                View
              </Button>
            </Tooltip>
          ),
        }));
        setFetchList(updatedData);
        setTotalData(response.data.total);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  // ======================= Handle Drawer Update =================================

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "accountUpdate",
      title: "Update Account",
      value: value,
    });
  };

  // ======================= Handle Drawer Add =================================

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "accountAdd",
      title: "Account Add",
      ID: null,
    });
  };

  // ====================================== Update Active Status ===========================

  const handleUptadeActive = async (value, ID) => {
    try {
      let message = "";
      if (value) {
        message = "Activate";
      } else {
        message = "Deactivate";
      }
      if (window.confirm("Are sure you want " + message + " Account")) {
        const formData = new FormData();
        formData.append("account_id", ID);
        formData.append("account_status", value);
        formData.append("action", "update");

        const response = await axios.post(
          "https://skdm.in/server/manage/admin-login.php",
          formData
        );
        if (response.data) {
          message.success("Update successfully");
        }
      }
    } catch (error) {
      message.error("Status Update Failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };
  return (
    <div>
      <TitleComp
        name="Account List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={10}
        type="Account"
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
