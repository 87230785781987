import { DatePicker, Typography } from "antd";
import React, { useState } from "react";
import { Button, Select } from "antd";
import { Form, Space } from "antd";
import frequensy from "../../JSON/frequensy.json";

const { Title } = Typography;

export default function BankInfo({
  loading,
  prev,
  enachDetail,
  setEnachDetail,
  handleSubmitData,
}) {
  const [form] = Form.useForm();


  const onFinish = async (values) => {
    console.log(values);
    setEnachDetail({
      ...enachDetail,
      first_collection_date: values.first_date,
      final_collection_date: values.final_date,
      frequency: values.frequency,
    });

    const fisrtDate = new Date(values.first_date);
    const finalData = new Date(values.final_date);

    if (
      handleSubmitData(fisrtDate, finalData, values.frequency)
    ) {
      form.resetFields();
    }
  };
  
  return (
    <div>
      <div className="packageSelectStep">
        <Title level={4}>eNach Details</Title>

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="first_date"
            label="First Collection Date"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "3px 4px",
            }}
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              placeholder="Select Date"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="final_date"
            label="Final Collection Date"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "3px 4px",
            }}
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker placeholder="Select Date" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="frequency"
            label="Frequency"
            style={{
              margin: "15px 4px",
            }}
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Payment Frequency"
              options={frequensy}
            ></Select>
          </Form.Item>

          <Form.Item style={{ margin: "20px 0" }}>
            <Space>
              <Button type="primary" loading={loading} htmlType="submit">
                Submit
              </Button>
              <Button danger loading={loading} onClick={prev}>
                Previous
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
