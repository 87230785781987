import React, { useEffect, useState } from "react";
import { Button, Radio, Select } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import "react-quill/dist/quill.snow.css";
import { LockOutlined } from "@ant-design/icons";
import bankOption from "../../JSON/bank-name.json";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function UpdateBank() {
  const { drawerData, toggleDrawer } = useDrawer();

  const [loading, setLoading] = useState(false);
  const { ID } = useParams();

  const [form] = Form.useForm();

  //   ============================== Call Update API ========================
  const onFinish = async (values) => {
    try {
      const bankName = bankOption
        .filter((valueData) => valueData.value === values.name)
        .map((valueData) => valueData.label);

      const formData = new FormData();
      formData.append("client_id", ID);
      formData.append("bank_name", bankName);
      formData.append("bank_code", values.name);
      formData.append("bank_type", values.type);
      formData.append("bank_branch_code", values.bank_branch_code);
      formData.append(
        "branch_code_type",
        values.branch_code_type ? "IFSC" : "MICR"
      );
      formData.append("bank_account_no", values.bank_account_no);
      formData.append("bank_id", values.bank_id);

      formData.append("action", "update");
      setLoading(true);
      const response = await axios.post(
        "https://skdm.in/server/manage/bank-details.php",
        formData
      );

      if (response.data) {
        setLoading(false);
        form.resetFields();
        toggleDrawer();
        message.success("Submit success!");
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    if (drawerData.value !== null) {
      form.setFieldsValue({
        bank_id: drawerData.value.bank_id,
        name: drawerData.value.bank_code,
        type: drawerData.value.bank_type,
        branch_code_type: drawerData.value.branch_code_type,
        bank_branch_code: drawerData.value.bank_branch_code,
        bank_account_no: drawerData.value.bank_account_no,
        confirm_account: drawerData.value.bank_account_no,
      });
    }
    // eslint-disable-next-line
  }, [drawerData]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="bank_id"
          label="Bank Id"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled placeholder="Bank Id" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Select Bank"
          hasFeedback
          style={{
            margin: "3px 4px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Bank"
            optionFilterProp="children"
            filterOption={filterOption}
            options={bankOption}
          ></Select>
        </Form.Item>
        <Form.Item
          name="type"
          label="Account Type"
          hasFeedback
          style={{
            margin: "3px 4px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Account Type"
            optionFilterProp="children"
            options={[
              {
                value: "SAVINGS",
                label: "Saving A/c",
              },
              {
                value: "CURRENT",
                label: "Current A/c",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="branch_code_type"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group buttonStyle="solid"style={{margin:"15px 0"}}>
            <Radio.Button value="IFSC">IFSC</Radio.Button>
            <Radio.Button value="MICR">MICR</Radio.Button>=
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="bank_branch_code"
          label="Branch Code"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Branch Code" />
        </Form.Item>
        <Form.Item
          name="bank_account_no"
          label="Account No."
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Input.Password
            placeholder="Account No."
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item
          name="confirm_account"
          label="Confirm Account No."
          dependencies={["password"]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("bank_account_no") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new account no that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Account No."
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
