import React, { useEffect, useState } from "react";
import TitleComp from "../components/Title/title";
import TableComp from "../components/table/tables";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { get } from "../services/userAuthAPI";
import moment from "moment";
import { Button } from "antd";
import { FaTrashAlt } from "react-icons/fa";
import QuoationModal from "../components/QuoationModal";

export default function Leads() {
  const columns = [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
    },
    {
      title: "Phone",
      dataIndex: "client_phone",
      key: "client_phone",
    },
    {
      title: "Email",
      dataIndex: "client_email",
      key: "client_email",
    },
    {
      title: "Business Name",
      dataIndex: "client_business_name",
      key: "client_business_name",
    },
    {
      title: "Category",
      dataIndex: "client_business_type",
      key: "client_business_type",
    },
    {
      title: "Status",
      dataIndex: "lead_status",
      key: "lead_status",
    },
    {
      title: "Reminder At",
      dataIndex: "remider",
      key: "remider",
    },
    {
      title: "Summary",
      dataIndex: "lead_desc",
      key: "lead_desc",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();

  const fetchData = async (page, limit, search) => {
    try {
      setLoading(true);
      const response = await get(
        `client.php?action=fetchLead&page=${page}&limit=${limit}&account_id=${id}&token=${token}${
          search !== "" ? "&search=" + search : ""
        }`
      );
      if (response) {
        setLoading(false);
        setTotalData(response.total);
        const updateList = response.list.map((value) => ({
          ...value,
          lead_status:
            value.client_status !== "active" ? (
              <span
                className={value.lead_status
                  ?.toLowerCase()
                  ?.replace(/\s+/g, "-")
                  ?.replace(/[^\w\-]+/g, "")
                  ?.replace(/\-\-+/g, "-")
                  ?.replace(/^-+/, "")
                  ?.replace(/-+$/, "")}
              >
                {value.lead_status}
              </span>
            ) : (
              <span className={value.client_status + "-client"}>
                {value.client_status}
              </span>
            ),
          remider:
          moment(value.remider).isValid()
          ? moment(value.remider).format("DD MMM YYYY hh:mm a")
          : "-",
          created_at: moment(value.created_at).format("DD MMM YY"),
          action: (
            <div>
              {value.quotation.length > 0 && (
                <QuoationModal quotation={value.quotation} />
              )}
              <Button
                style={{ margin: "10px 0" }}
                icon={<FaTrashAlt style={{ color: "orange" }} />}
              ></Button>
            </div>
          ),
        }));
        setFetchList(updateList);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp, ""]);

  return (
    <div>
      <TitleComp
        name="Lead List"
        handleDrawerAdd={""}
        totalData={totalData}
        type="Leads"
        isShowButton={false}
      />

      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
    </div>
  );
}
