import { Button, Col, Divider, Row } from "antd";
import { Typography } from "antd";
import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdCall, MdEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { BsCurrencyRupee } from "react-icons/bs";
import moment from "moment";
import { useDrawer } from "../../../context/drawer";

export default function EnachView() {
  const { Title, Text } = Typography;
  const { drawerData } = useDrawer();

  console.log(drawerData);
  return (
    <div>
      <Row>
        <Col span={12}>
          <Title level={4}>eNach Status</Title>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={12}>
          <Button
            shape="round"
            className="checkStatus"
            icon={<AiOutlineCheckCircle />}
          >
            Check eNach Status
          </Button>
        </Col>
      </Row>
      <Divider orientation="left">Client Details</Divider>
      <Row>
        <Col span={24}>
          <Title
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
            }}
            level={5}
          >
            {drawerData.value.client_name}
          </Title>
        </Col>
        <Col span={12}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <MdCall />
            &nbsp; {drawerData.value.client_phone}
          </Text>
        </Col>
        <Col span={12}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <MdEmail />
            &nbsp; {drawerData.value.client_email}
          </Text>
        </Col>
      </Row>
      <Divider orientation="left">Package & Bank Details</Divider>
      <Row>
        {/* ================= Package Name =========== */}
        <Col span={10}>
          <Text level={5}>Package Name</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.package_name}
          </Text>
        </Col>

        {/* ================= Bank Name =========== */}
        <Col span={10}>
          <Text level={5}>Bank Name</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.bank_name}
          </Text>
        </Col>

        {/* ================= Booking No. =========== */}
        <Col span={10}>
          <Text level={5}>Account No.</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.bank_account_no}
          </Text>
        </Col>

        {/* ================= Account Type =========== */}
        <Col span={10}>
          <Text level={5}>Account Type</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.bank_type}
          </Text>
        </Col>

        {/* ================= Branch Code Type =========== */}
        <Col span={10}>
          <Text level={5}>Branch Code Type</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.branch_code_type}
          </Text>
        </Col>
        {/* ================= Branch Code =========== */}
        <Col span={10}>
          <Text level={5}>Branch Code</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.bank_branch_code}
          </Text>
        </Col>
      </Row>
      {/* ================= eNach Details =========== */}
      <Divider orientation="left">eNach Details</Divider>
      <Row>
        {/* ======================= Response ID ================ */}

        <Col span={10}>
          <Text level={5}>Response Id</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.enach_api_id}
          </Text>
        </Col>

        {/* ============================ Refrence ID ================= */}
        <Col span={10}>
          <Text level={5}>Refrence ID</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.refrence_id}
          </Text>
        </Col>

        {/* ================== Start Date ============== */}
        <Col span={10}>
          <Text level={5}>Start Date</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {moment(drawerData.value.start_date).format("DD MMM YYYY")}
          </Text>
        </Col>

        {/* ======================= End Date ================= */}

        <Col span={10}>
          <Text level={5}>End Date</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {moment(drawerData.value.end_date).format("DD MMM YYYY")}
          </Text>
        </Col>

        {/* ======================= Due Date ================= */}

        <Col span={10}>
          <Text level={5}>Due Date At</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.due_date}
          </Text>
        </Col>

        {/* ======================= Frequency ================= */}

        <Col span={10}>
          <Text level={5}>Frequency</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.payment_frequensy}
          </Text>
        </Col>
      </Row>
      <Divider orientation="left">Booking Details</Divider>
      {/* ======================= Total Detail ================= */}
      <Row>
        <Col span={10}>
          <Text level={5}>Booking Id</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.order_id}
          </Text>
        </Col>

        <Col span={10}>
          <Text level={5}>Amount</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            <BsCurrencyRupee />
            &nbsp;{drawerData.value.order_total_amount}
          </Text>
        </Col>

        <Col span={10}>
          <Text level={5}>Year</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.order_year}
          </Text>
        </Col>
      </Row>
    </div>
  );
}
