import { Col, Divider, Empty, Row, Select, Space, Form, Button } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography } from "antd";
import { MdCall, MdEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";

const { Title, Text } = Typography;
const { Option } = Select;

export default function ClientInfo({
  next,
  prev,
  clientValue,
  setClientValue,
  bankValue,
  setBankValue,
}) {
  const [loading, setLoading] = useState(false);
  const [bankDetail, setBankDetail] = useState(null);
  const [option, setOption] = useState([]);
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit, search) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);
      const response = await axios.post(
        "https://skdm.in/server/manage/client-details.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          "",
        formData
      );
      if (response.data) {
        setLoading(false);
        setOption(response.data.list);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(0, 10, "");
    // eslint-disable-next-line
  }, []);

  // =========================== Handle Value Select ===================

  const handleValueSelect = (newValue) => {
    const selectOption = option.filter((valueData) =>
      newValue.includes(valueData.client_name)
    );
    setClientValue(selectOption);
    setBankDetail(selectOption[0].banks);
  };

  const handleValueSelectBank = (newValue) => {
    const selectOption = bankDetail.filter((valueData) =>
      newValue.includes(valueData.bank_name)
    );
    setBankValue(selectOption);
  };

  // ================= Submit Selected Data ===============
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    next();
  };

  return (
    <div>
      <div className="packageSelectStep">
        {/* ======================= Selecte Client ================== */}
        <Title level={4}>Select Client</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="client"
            label=""
            style={{
              margin: "3px 4px",
            }}
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              loading={loading}
              style={{
                width: "100%",
              }}
              onSearch={(valueSearch) => fetchData(0, 10, valueSearch)}
              onSelect={handleValueSelect}
              // onSearch={(newValue) => fetchData(0, 5, newValue)}
              placeholder="Select Packages..."
              maxTagCount="responsive"
            >
              {option.map((value) => (
                <Option value={value.client_name} label={value.client_name}>
                  <Space>{value.client_name}</Space>
                </Option>
              ))}
            </Select>
          </Form.Item>

          {clientValue ? (
            <>
              {/* ======================= Selected Client Details ================ */}
              {clientValue.map((fatchValue) => (
                <div className="packageDetail" style={{ margin: "20px 0" }}>
                  <Title level={5}>{fatchValue.client_name}</Title>
                  <Row>
                    <Col span={12}>
                      <Text
                        className="valueBooking"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        strong
                      >
                        <MdCall />
                        &nbsp; +91&nbsp;{fatchValue.client_phone}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text
                        className="valueBooking"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        strong
                      >
                        <MdEmail />
                        &nbsp;{fatchValue.client_email}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text
                        className="valueBooking"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        strong
                      >
                        <ImLocation />
                        &nbsp;{fatchValue.client_address}
                      </Text>
                    </Col>
                  </Row>
                </div>
              ))}
              <Divider />
              {/* ===================== From Api Bank Select ==================== */}
              <Form.Item
                name="bank"
                label=""
                style={{
                  margin: "3px 4px",
                }}
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  placeholder="Select Bank Detail..."
                  maxTagCount="responsive"
                  onSelect={handleValueSelectBank}
                >
                  {bankDetail &&
                    bankDetail.map((value) => (
                      <Option value={value.bank_name} label={value.bank_name}>
                        <Space>{value.bank_name}</Space>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {/* ======================== Bank Add Form ==================== */}
              {bankValue ? (
                bankValue.map((fatchValue) => (
                  <div className="packageDetail" style={{ margin: "20px 0" }}>
                    <Title level={5}>{fatchValue.bank_nam}</Title>
                    <Row>
                      <Col span={10}>
                        <Text strong>Account No.</Text>
                      </Col>
                      <Col span={4}>:</Col>
                      <Col span={10}>
                        <Text
                          className="valueBooking"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                          strong
                        >
                          {fatchValue.bank_account_no}
                        </Text>
                      </Col>

                      {/* ================================== Account Type =============== */}
                      <Col span={10}>
                        <Text strong>Account Type</Text>
                      </Col>
                      <Col span={4}>:</Col>
                      <Col span={10}>
                        <Text
                          className="valueBooking"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                          strong
                        >
                          {fatchValue.bank_type}
                        </Text>
                      </Col>

                      {/* ================================== Branch code Type =============== */}

                      <Col span={10}>
                        <Text strong>Branch Code Type</Text>
                      </Col>
                      <Col span={4}>:</Col>
                      <Col span={10}>
                        <Text
                          className="valueBooking"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                          strong
                        >
                          {fatchValue.branch_code_type}
                        </Text>
                      </Col>

                      {/* ================================== Branch code =============== */}
                      <Col span={10}>
                        <Text strong>Branch Code</Text>
                      </Col>
                      <Col span={4}>:</Col>
                      <Col span={10}>
                        <Text
                          className="valueBooking"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                          strong
                        >
                          {fatchValue.bank_branch_code}
                        </Text>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <div className="packageDetail" style={{ margin: "30px 0" }}>
                  <Empty />
                </div>
              )}
            </>
          ) : (
            <div className="packageDetail" style={{ margin: "30px 0" }}>
              <Empty />
            </div>
          )}

          <Form.Item style={{ margin: "20px 0" }}>
            <Space>
              <Button type="primary" loading={loading} htmlType="submit">
                Next
              </Button>
              <Button danger onClick={prev}>
                Previous
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
