import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Empty, Row, Select, Space, Form, Button } from "antd";
import { Typography } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { BsCurrencyRupee } from "react-icons/bs";
import { useDrawer } from "../../../context/drawer";

const { Title } = Typography;
const { Option } = Select;

const PackageSelect = ({packageValue, setPackageValue, next}) => {
  const [option, setOption] = useState([]);
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit, search) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      const response = await axios.post(
        "https://skdm.in/server/manage/package-details.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          "",
        formData
      );
      if (response.data) {
        setOption(response.data.list);
      }
    } catch (error) {
      // Handle errors
      console.error("Error making POST request", error);
    }
  };
  useEffect(() => {
    fetchData(0, 15, "");
    // eslint-disable-next-line
  }, []);

  // =========================== Handle Value Select ===================

  const handleValueSelect = (newValue) => {
    const selectOption = option.filter((valueData) =>
      newValue.includes(valueData.package_id)
    );
    setPackageValue(selectOption);
  };

  function sumPrices(priceArray) {
    // Initialize a variable to store the sum
    let total = 0;

    // Iterate through the array and add up the values
    for (let i = 0; i < priceArray.length; i++) {
      total += parseFloat(priceArray[i].package_price);
    }

    return total;
  }

  // ======================================= Price Update ======================================

  function updateItemById(idToUpdate, year) {
    // Find the index of the item with the matching ID
    const index = packageValue.findIndex((item) => item.package_id === idToUpdate);
    const price = option
      .filter((valueFid) => valueFid.package_id === idToUpdate)
      .map((valueFound) => valueFound.package_price);

    const updatePrice = {
      package_year:year,
      package_price: parseFloat(year) * parseFloat(price),
    };
    // If the item with the ID is found, update its properties
    if (index !== -1) {
      packageValue[index] = { ...packageValue[index], ...updatePrice };

      setPackageValue([...packageValue]);
    }
  }

  // ================= Submit Selected Data ===============
  const { toggleDrawer } = useDrawer();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    next();
  };
  

  return (
    <div className="packageSelectStep">
      <Title level={4}>Select Packages</Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="package"
          label=""
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            style={{
              width: "100%",
            }}
            option={option}
            onChange={handleValueSelect}
            // onSearch={(newValue) => fetchData(0, 5, newValue)}
            placeholder="Select Packages..."
            maxTagCount="responsive"
          >
            {option.map((value) => (
              <Option value={value.package_id} label={value.package_name}>
                <Space>{value.package_name}</Space>
              </Option>
            ))}
          </Select>
        </Form.Item>
        {packageValue && packageValue.length > 0 ? (
          <>
            {packageValue.map((fatchValue) => (
              <div className="packageDetail" style={{ margin: "20px 0" }}>
                <Card type="inner" title={fatchValue.package_name}>
                  <Row>
                    {/* ================== Year Select ============= */}
                    <Col span={10}>
                      <Title level={5}>Year</Title>
                    </Col>
                    <Col span={4}>
                      <Title level={5}>:</Title>
                    </Col>
                    <Col
                      style={{ display: "flex", justifyContent: "end" }}
                      span={10}
                    >
                      <Select
                        defaultValue={fatchValue.package_year + " Year"}
                        style={{
                          width: 120,
                        }}
                        onChange={(value) =>
                          updateItemById(fatchValue.package_id, value)
                        }
                        options={[
                          {
                            value: 1,
                            label: "1 Year",
                          },
                          {
                            value: 2,
                            label: "2 Year",
                          },
                          {
                            value: 3,
                            label: "3 Year",
                          },
                          {
                            value: 4,
                            label: "4 Year",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/* ================== Price ============= */}
                    <Col span={10}>
                      <Title level={5}>Amount</Title>
                    </Col>
                    <Col span={4}>
                      <Title level={5}>:</Title>
                    </Col>
                    <Col
                      style={{ display: "flex", justifyContent: "end" }}
                      span={10}
                    >
                      <Title className="valueBooking" level={5}>
                        <BsCurrencyRupee />
                        &nbsp;{fatchValue.package_price}
                      </Title>
                    </Col>
                  </Row>{" "}
                </Card>
              </div>
            ))}
            <Divider />
            <Row>
              {/* ================== Price ============= */}
              <Col span={10}>
                <Title level={5}>Subtotal</Title>
              </Col>
              <Col span={4}>
                <Title level={5}>:</Title>
              </Col>
              <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
                <Title className="valueBooking" level={5}>
                  <BsCurrencyRupee />
                  &nbsp;
                  {sumPrices(packageValue)}
                </Title>
              </Col>
            </Row>
          </>
        ) : (
          <div className="packageDetail" style={{ margin: "30px 0" }}>
            <Empty />
          </div>
        )}
         <Form.Item style={{margin:"20px 0"}}>
            <Space>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
              <Button danger onClick={toggleDrawer}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
      </Form>
    </div>
  );
};
export default PackageSelect;
