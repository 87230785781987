import React, { useEffect, useState } from "react";
import { Button, Divider, Select } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
// import axios from "axios";
import { useSelector } from "react-redux";
import { get, post } from "../../../services/userAuthAPI";

export default function UpdateForm() {
  const { drawerData, toggleDrawer } = useDrawer();
  const [createdBy, setCreatedBy] = useState("Self");
  const [loading, setLoading] = useState(false);
  const [emplList, setEmpList] = useState();
  const { token } = useSelector((state) => state.auth);

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("client_id", values.ID);
      formData.append("client_name", values.name);
      formData.append("client_phone", values.phone);
      formData.append("client_email", values.email);
      formData.append("client_address", values.address);
      formData.append("client_business_name", values.business_name);
      formData.append("client_business_type", values.business_type);
      formData.append("client_gst_number", values.gst_number);
      formData.append("created_by", values.created_by);
      formData.append("action", "update");
      formData.append(
        "account_id",
        values.created_by === "Self" ? "" : values.emp_id
      );
      formData.append("slug", values.name.toLowerCase().split(" ").join("-"));
      formData.append("token", token);

      setLoading(true);
      const response = await post("client.php", formData);
      if (response) {
        setLoading(false);
        if (response.status === "Already have client") {
          message.error("Number Already Registered!");
        } else {
          toggleDrawer();
          message.success("Update success!");
        }
      }
    } catch (error) {
      setLoading(false);
      message.error("Update failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  useEffect(() => {
    if (drawerData.value !== null) {
      form.setFieldsValue({
        ID: drawerData.value.client_id,
        name: drawerData.value.client_name,
        phone: drawerData.value.client_phone,
        email: drawerData.value.client_email,
        address: drawerData.value.client_address,
        business_name: drawerData.value.client_business_name,
        business_type: drawerData.value.client_business_type,
        gst_number: drawerData.value.client_gst_number,
        created_by: drawerData.value.created_by,
        account_id: drawerData.value.account_id,
      });

      setCreatedBy(drawerData.value.created_by);
    }
    // eslint-disable-next-line
  }, [drawerData]);

  // ========================= Empl List ====================

  const fetchData = async (page, limit, search) => {
    try {
      const response = await get(
        `account.php?action=fetch&page=${page}&limit=${limit}&token=${token}${
          search !== "" ? "&search=" + search : ""
        }`
      );
      if (response) {
        setEmpList(response.list);
      }
    } catch (error) {
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(0, 10, "");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="ID"
          label="Client ID"
          style={{
            display: "none",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label="Client Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Client Number"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            { max: 10 },
            { min: 10 },
          ]}
        >
          <Input type="number" addonBefore="+91" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Client Email"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            { type: "email", warningOnly: true },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="Client Address"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Divider orientation="left"> Business Info</Divider>
        <Form.Item
          name="business_name"
          label="Buiness Name"
          style={{ margin: "3px 4px" }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="business_type"
          label="Business Type"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="gst_number"
          label="GST Number"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Divider orientation="left">Created By</Divider>

        <Form.Item
          name="created_by"
          label="Created By"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
        >
          <Select
            style={{ width: 150 }}
            onChange={(value) => setCreatedBy(value)}
            options={[
              { value: "Self", label: "Self" },
              { value: "Agent", label: "Agent" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="emp_id"
          label="Select Agent"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
        >
          <Select
            showSearch
            disabled={createdBy === "Self" ? true : false}
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
          >
            {emplList && emplList.length > 0
              ? emplList.map((valueEmp) => (
                  <Select.Option
                    value={valueEmp.account_id}
                    label={valueEmp.account_name}
                  >
                    {valueEmp.account_name}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
