import React, { useState } from "react";
import { Button } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PiCurrencyInrBold } from "react-icons/pi";
import { useSelector } from "react-redux";
import { post } from "../../../services/userAuthAPI";

export default function AddServices() {
  const { toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [serviceSummary, setServiceSummary] = useState(null);

  // ========================= Select Functionalty ==========================

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("service_name", values.name);
      formData.append("service_desc", serviceSummary);
      formData.append("price", values.price);
      formData.append("action", "addService");
      formData.append("token", token);

      setLoading(true);
      const response = await post("package.php", formData);
      if (response) {
        setLoading(false);
        form.resetFields();
        toggleDrawer();
        message.success("Submit success!");
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Service Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label="Service Price"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            { min: 4 },
          ]}
        >
          <Input type="number" addonBefore={<PiCurrencyInrBold />} />
        </Form.Item>

        <Form.Item
          label="Write about service"
          style={{
            margin: "3px 4px",
          }}
          name="service"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactQuill
            theme="snow"
            style={{ margin: "10px 0 0 0" }}
            value={serviceSummary}
            onChange={(value) => setServiceSummary(value)}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
