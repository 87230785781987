import React, { useState } from "react";
import { Button, Divider, Empty, Select } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import serviceList from "../../JSON/services-list.json";
import { PiCurrencyInrBold } from "react-icons/pi";
import { useSelector } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";
import { post } from "../../../services/userAuthAPI";
import TextArea from "antd/es/input/TextArea";

export default function PackageAddForm() {
  const { toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [packageServiceList, setPackageServiceList] = useState([]);

  // ========================= Select Functionalty ==========================

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string, value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("package_name", values.name);
      formData.append("slug", values.name.toLowerCase().split(" ").join("-"));
      formData.append("package_desc", values.summary);
      formData.append("package_price", values.price);
      formData.append("package_year", values.year);
      formData.append("package_status", true);
      formData.append("package_discount", values.discount);
      formData.append("package_type", values.type);
      formData.append("serviceList", JSON.stringify(packageServiceList));
      formData.append("action", "add");
      formData.append("token", token);

      setLoading(true);
      const response = await post("package.php", formData);
      if (response) {
        setLoading(false);
        form.resetFields();
        toggleDrawer();
        message.success("Submit success!");
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const handleAddServices = () => {
    const data = {
      service_name: "",
      service_desc: "",
      price: "",
      type: "",
    };

    setPackageServiceList((prev) => [...prev, data]);
  };

  const handleRemoveData = (index) => {
    if (window.confirm("Are you sure want to remove !")) {
      const prevData = [...packageServiceList]; // Create a shallow copy
      prevData.splice(index, 1); // Remove one item at the specified index
      setPackageServiceList(prevData);
    }
  };

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Package Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label="Package Price"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
            { min: 4 },
          ]}
        >
          <Input type="number" addonBefore={<PiCurrencyInrBold />} />
        </Form.Item>  
        <Form.Item
          name="year"
          label="Package Year"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input addonAfter="Year" />
        </Form.Item>
        <Form.Item
          name="discount"
          label="Package Discount"
          hasFeedback
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input addonAfter="%" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Package Type"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Service"
            optionFilterProp="children"
            filterOption={filterOption}
            options={serviceList}
          />
        </Form.Item>

        <Form.Item
          name="summary"
          label="Package Summary"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea placeholder="Write here.." showCount maxLength={250} />
        </Form.Item>

        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Divider
            orientation="left"
            style={{ maxWidth: "100px", minWidth: "70%" }}
          >
            Service List{" "}
          </Divider>
          <Button
            onClick={handleAddServices}
            style={{ margin: "0 10px", background: "#000", color: "#fff" }}
          >
            Add Service
          </Button>
        </div>
        {packageServiceList?.length > 0 ? (
          packageServiceList.map((value, index) => (
            <div
              style={{
                borderBottom: "1px solid #afafaf",
                marginBottom: "15px",
                paddingBottom: "15px",
              }}
            >
              <div
                className="mainHeader"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Input
                  value={value.service_name}
                  placeholder="Service Name"
                  style={{ marginBottom: "10px", marginRight: "10px" }}
                  onChange={(e) =>
                    setPackageServiceList((prev) => {
                      const newList = [...prev];
                      newList[index] = {
                        ...newList[index],
                        service_name: e.target.value,
                      };
                      return newList;
                    })
                  }
                />

                <Input
                  value={value.price}
                  placeholder="Service Price"
                  style={{ marginBottom: "10px", marginRight: "10px" }}
                  prefix={<PiCurrencyInrBold />}
                  onChange={(e) =>
                    setPackageServiceList((prev) => {
                      const newList = [...prev];
                      newList[index] = {
                        ...newList[index],
                        price: e.target.value,
                      };
                      return newList;
                    })
                  }
                />

                <Button
                  danger
                  style={{ marginBottom: "10px" }}
                  onClick={() => handleRemoveData(index)}
                >
                  <FaTrashAlt />
                </Button>
              </div>
              <ReactQuill
                theme="snow"
                value={value.service_desc}
                onChange={(value) =>
                  setPackageServiceList((prev) => {
                    const newList = [...prev];
                    newList[index] = { ...newList[index], service_desc: value };
                    return newList;
                  })
                }
              />
            </div>
          ))
        ) : (
          <Empty />
        )}

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
