import { Button, Col, Divider, Row } from "antd";
import { Typography } from "antd";
import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdCall, MdEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { BsCurrencyRupee } from "react-icons/bs";
import moment from "moment";
import { useDrawer } from "../../../context/drawer";

export default function BookingView() {
  const { Title, Text } = Typography;
  const { drawerData } = useDrawer();

  return (
    <div>
      <Row>
        <Col span={12}>
          <Title level={3}>Booking Status</Title>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={12}>
          <Button
            style={{
              background:
                drawerData.value.order_status === "success"
                  ? "green"
                  : drawerData.value.payment.payment_status === "pending"
                  ? "orange"
                  : "red",
              display: "flex",
              alignItems: "center",
              color: "#fff",
            }}
            shape="round"
            icon={<AiOutlineCheckCircle />}
          >
            {drawerData.value.order_status}
          </Button>
        </Col>
      </Row>
      <Divider orientation="left">Client Details</Divider>
      <Row>
        <Col span={24}>
          <Title
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
            }}
            level={5}
          >
            {drawerData.value.client_name}
          </Title>
        </Col>
        <Col span={12}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <MdCall />
            &nbsp; +91&nbsp;{drawerData.value.client_phone}
          </Text>
        </Col>
        <Col span={12}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <MdEmail />
            &nbsp;{drawerData.value.client_email}
          </Text>
        </Col>
        <Col span={24}>
          <Text
            className="valueBooking"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            strong
          >
            <ImLocation />
            &nbsp;{drawerData.value.client_address}
          </Text>
        </Col>
        <Col span={10}>
          <Title level={5}>Referrence</Title>
        </Col>
        <Col>
          <Text strong>:</Text>
        </Col>
        <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
          <Text level={5} className="valueBooking">
            {drawerData.value.created_by === "Self" ? drawerData.value.created_by : drawerData.value.emp_name}
          </Text>
        </Col>
      </Row>
      <Divider orientation="left">Booking Details</Divider>
      <Row>
        {/* ================= Order Id =========== */}
        <Col span={10}>
          <Text level={5}>Order Id</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.order_id}
          </Text>
        </Col>
        {/* ================= Package Name =========== */}
        <Col span={10}>
          <Text level={5}>Package Name</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.package_name}
          </Text>
        </Col>

        {/* ================= Booking Type =========== */}
        <Col span={10}>
          <Text level={5}>Method</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.order_type}
          </Text>
        </Col>

        {/* ================= Booking Date =========== */}
        <Col span={10}>
          <Text level={5}>Date</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {moment(drawerData.value.created_at).format("DD MMM YYYY")}
          </Text>
        </Col>
        <Col span={10}>
          <Text level={5}>Payemnt Response Id</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.payment.transaction_id}
          </Text>
        </Col>
        
      </Row>

      <Divider orientation="left">Payment Details</Divider>
      <Row>
        {/* ======================= Items Detail ================= */}
        {/* 
        <Col span={10}>
          <Text level={5}>Item</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            01
          </Text>
        </Col> */}

        {/* ======================= Year Detail ================= */}

        <Col span={10}>
          <Text level={5}>Year</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            {drawerData.value.order_year}&nbsp;Years
          </Text>
        </Col>

        {/* ======================= sub total Detail ================= */}

        <Col span={10}>
          <Text level={5}>Subtotal</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            <BsCurrencyRupee />
            &nbsp;{drawerData.value.order_subtotal_amount}
          </Text>
        </Col>
        {/* ======================= Tax Detail ================= */}

        <Col span={10}>
          <Text level={5}>Tax</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            <BsCurrencyRupee />
            500
          </Text>
        </Col>
      </Row>
      <Divider />
      {/* ======================= Total Detail ================= */}
      <Row>
        <Col span={10}>
          <Text level={5}>Total</Text>
        </Col>
        <Col span={4}>
          <Text level={5}>:</Text>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
          <Text className="valueBooking" level={5}>
            <BsCurrencyRupee />
            &nbsp;{drawerData.value.order_total_amount}
          </Text>
        </Col>
      </Row>
    </div>
  );
}
