import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
import { Button, Switch, Tooltip, message } from "antd";
import DrawerComp from "../components/Drower/Drawer";
import { useDrawer } from "../context/drawer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get, post } from "../services/userAuthAPI";

export default function ClientList() {
  const columns = [
    {
      title: "Name",
      dataIndex: "client_name",
      key: "client_name",
      width: "20%",
    },
    {
      title: "Number",
      dataIndex: "client_phone",
      key: "client_phone",
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "client_email",
      key: "client_email",
      width: "15%",
    },
    {
      title: "Business Name",
      dataIndex: "client_business_name",
      key: "client_business_name",
      width: "25%",
    },
    {
      title: "Emplyee Name",
      dataIndex: "account_name",
      key: "account_name",
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "work_status",
      key: "work_status",
      width: "10%",
    },

    {
      title: "",
      dataIndex: "view",
      key: "",
    },
    {
      title: "",
      dataIndex: "banks",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(0);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);

  // =========================================== Drawer Context =========================

  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit, search) => {
    try {
      setLoading(true);
      const response = await get(
        `client.php?action=fetch&page=${page}&limit=${limit}&token=${token}${
          search !== "" ? "&search=" + search : ""
        }`
      );

      if (response) {
        setLoading(false);
        const updatedData = response.list.map((item) => ({
          ...item,
          work_status: (
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Deactive"
              onChange={(value) =>
                handleUptadeActive(
                  value ? "active" : "unactive",
                  item.client_id
                )
              }
              defaultChecked={item.work_status === "active"}
            />
          ),

          account_name:
            item.created_by === "Self" ? item.created_by : item.account_name,
          view: (
            <Tooltip title="View Client Details" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerUpdate(item)}
              >
                View
              </Button>
            </Tooltip>
          ),

          banks: (
            <Tooltip title="View Client Details" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => navigate("/banks/" + item.client_id)}
              >
                Banks
              </Button>
            </Tooltip>
          ),
        }));
        setFetchList(updatedData);
        setTotalData(response.total);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  // ======================= Handle Drawer Update =================================

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "clientUpdate",
      title: "Update Data",
      value: value,
    });
  };

  // ======================= Handle Drawer Add =================================

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "clientAdd",
      title: "Client Add",
      ID: null,
    });
  };

  // ========================= Handle View Banks ============================
  const navigate = useNavigate();

  // ====================================== Update Active Status ===========================

  const handleUptadeActive = async (value, ID) => {
    try {
      let message = "";
      if (value) {
        message = "Activate";
      } else {
        message = "Deactivate";
      }
      if (window.confirm("Are sure you want " + message + " Account")) {
        const formData = new FormData();
        formData.append("client_id", ID);
        formData.append("work_status", value);
        formData.append("action", "update");
        formData.append("token", token);

    
        const response = await post("client.php", formData);
        if (response.data) {
          message.sucess("Updated successfully");
        }
      }
    } catch (error) {
      message.error("Status Update Failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };
  return (
    <div>
      <TitleComp
        name="Client List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={totalData}
        type="Client"
        isShowButton={true}
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
