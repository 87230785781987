import React, { useEffect, useState } from "react";
import { Button, Modal, Table, message } from "antd";
import { FaFilePdf } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import moment from "moment";
import { FaTrashAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { post } from "../services/userAuthAPI";
import { useSelector } from "react-redux";

const QuoationModal = ({ quotation }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token } = useSelector((state) => state.auth);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Quatation Name",
      dataIndex: "quotation_name",
      key: "quotation_name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Package Type",
      dataIndex: "package_type",
      key: "package_type",
    },

    {
      title: "Sent At",
      dataIndex: "created_at",
      key: "created_at",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const [list, setList] = useState(quotation);

  useEffect(() => {
    const data = quotation.map((value) => ({
      ...value,
      created_at: moment(value.created_at).format("DD MMM YYYY hh:mm a"),
      action: (
        <div className="d-flex">
          <NavLink target="_blank" to={value.quotation_path}>
            <Button icon={<FaEye style={{ color: "blue" }} />}></Button>
          </NavLink>
          <Button
            style={{ margin: "0 10px" }}
            icon={<FaTrashAlt style={{ color: "red" }} />}
            onClick={() => RemoveQuoate(value.quotation_id)}
          ></Button>
        </div>
      ),
    }));

    setList(data);
  }, [quotation]);

  const RemoveQuoate = async ($id, index) => {
    try {
      if (window.confirm("Are sure you want to remove")) {
        const formData = new FormData();

        formData.append("quotation_id", $id);
        formData.append("token", token);
        formData.append("action", "removeQuotation");

        const resp = await post("client.php", formData);

        if (resp) {
          message.success("Removed Successfully");
          const prevData = list;
          prevData.splice(index, 1);
          setList([...prevData]);
        }
      }
    } catch (error) {
      message.error("Sorry unable to remove please try again");
      console.error(error);
    }
  };

  return (
    <>
      <Button
        onClick={showModal}
        icon={<FaFilePdf style={{ color: "red" }} />}
      ></Button>
      <Modal
        width={700}
        onCancel={handleCancel}
        title="Quation List"
        open={isModalOpen}
        footer={[]}
      >
        <Table dataSource={list} columns={columns} pagination={false} />
      </Modal>
    </>
  );
};
export default QuoationModal;
