import React, { useRef, useState } from "react";
import { Button, Input, Spin } from "antd";
import { IoQrCodeSharp } from "react-icons/io5";
import QRCode from "react-qr-code";
import { MdDownload } from "react-icons/md";
import html2canvas from "html2canvas";

const { TextArea } = Input;

export default function QrCode() {
  const [enterValue, setEnterValue] = useState();
  const [qrValue, setQrValue] = useState("");
  const [loading, setLoading] = useState(false);
  const divRef = useRef(null);

  const handleQrCodeGenearet = () => {
    setLoading(true);
    setTimeout(() => {
      setQrValue(enterValue);
      setLoading(false);
    }, 700);
  };

  const [filename, setFilename] = useState("screenshot");

  const captureAndDownload = async () => {
    if (divRef.current) {
      const canvas = await html2canvas(divRef.current);
      const imgData = canvas.toDataURL("image/png");

      // Create a link element
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${filename}.png`;

      // Append to the document and trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="conatiner">
      <div className="row">
        <div className="col-md-6">
          <TextArea
            showCount
            rows={8}
            placeholder="Write here.."
            style={{ borderColor: "#000" }}
            onChange={(e) => setEnterValue(e.target.value)}
          />
          <Button
            className="otherButtons active d-flex align-items-center mt-3"
            onClick={handleQrCodeGenearet}
          >
            <IoQrCodeSharp />
            &nbsp;Generate
          </Button>
        </div>
        <div className="col-md-6">
          <Spin spinning={loading}>
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 300,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div ref={divRef} style={{ background: "#fff" }}>
                <QRCode
                  size={500}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={qrValue}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <div className="inputDetail d-flex align-items-center mt-4">
                <Input
                  placeholder="Enter name"
                  onChange={(e) => setFilename(e.target.value)}
                />
                <Button
                  className="otherButtons active text-center mx-2"
                  onClick={captureAndDownload}
                >
                  <MdDownload />
                  &nbsp;Download
                </Button>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
}
