import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
import axios from "axios";
import { Button, Popconfirm, Tooltip, message } from "antd";
import DrawerComp from "../components/Drower/Drawer";
import { useDrawer } from "../context/drawer";
import { useSelector } from "react-redux";
import { FaTrashAlt, FaEdit } from "react-icons/fa"; // Import edit icon

export default function Blog() {
  const columns = [
    {
      title: "Blog ID",
      dataIndex: "blog_id",
      key: "blog_id",
      width: "10%",
    },
    {
      title: "Title",
      dataIndex: "blog_title",
      key: "blog_title",
      width: "25%",
    },
    {
      title: "Description",
      dataIndex: "blog_desc",
      key: "blog_desc",
      width: "40%",
    },
    {
      title: "Slug",
      dataIndex: "blog_slug",
      key: "blog_slug",
      width: "15%",
    },
    {
      title: "Image",
      dataIndex: "blog_img",
      key: "blog_img",
      width: "15%",
      render: (text) => (
        <img
          src={text}
          alt="Blog"
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "view",
      key: "view",
    },
    {
      title: "",
      dataIndex: "update",
      key: "update",
    },
    {
      title: "",
      dataIndex: "remove",
      key: "remove",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(0);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);

  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit, search) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://shreekrishnadigital.com/api/blog.php?action=blogFetch&page=${page}&limit=${limit}&search=${search}`
      );
      if (response.data) {
        setLoading(false);
        const updatedData = response.data.list.map((item) => ({
          ...item,

          // view: (
          //   <Tooltip title="View Blog Details" placement="bottom">
          //     <Button
          //       className="otherButtons"
          //       onClick={() => handleDrawerView(item)}
          //     >
          //       View
          //     </Button>
          //   </Tooltip>
          // ),

          update: (
            <Tooltip title="Update Blog" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerUpdate(item)} // Trigger blog update
              >
                <FaEdit /> {/* Edit Icon */}
              </Button>
            </Tooltip>
          ),

          remove: (
            <Popconfirm
              title="Remove Blog"
              description="Are you sure to remove this blog?"
              onConfirm={() => confirm(item.blog_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>
                <FaTrashAlt />
              </Button>
            </Popconfirm>
          ),
        }));
        setFetchList(updatedData);
        setTotalData(response.data.total);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error making API request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
  }, [pageComp, limitComp]);

  // Handle Blog Update (opens the drawer with the blog data for editing)
  const handleDrawerUpdate = (blog) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "UpdateBlog", // Type for blog update
      title: "UpdateBlog",
      value: blog, // Pass the blog details to the drawer for editing
    });
  };

  // Handle Drawer View (opens the drawer for viewing the blog)
  const handleDrawerView = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "blogView", // Type for blog view
      title: "View Blog",
      value: value, // Pass the blog details for viewing
    });
  };

  // Handle Drawer Add (opens the drawer for adding a new blog)
  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "AddBlog",
      title: "Add Blog",
      ID: null,
    });
  };

  // Remove Blog
  const confirm = async (ID) => {
    try {
      const formData = new FormData();
      formData.append("blog_id", ID);
      formData.append("action", "remove");

      const response = await axios.post(
        "https://shreekrishnadigital.com/api/blog.php?action=remove",
        formData
      );
      if (response.data) {
        message.success("Blog removed successfully!");
        fetchData(pageComp, limitComp, "");
      }
    } catch (error) {
      message.error("Failed to remove blog!");
      console.error("Error making API request", error);
    }
  };

  return (
    <div>
      <TitleComp
        name="Blog List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={totalData}
        type="Blog"
        isShowButton={true}
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
