import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ClientList from "./pages/client-list";
import React from "react";
import PackageList from "./pages/package-list";
import EmployeeList from "./pages/employee-list";
import BookingList from "./pages/booking-list";
import Login from "./pages/login";
import LayoutContext from "./context/layout-context";
import NotFound from "./pages/404";
import Dashboard from "./pages/dashboard";
import { useSelector } from "react-redux";
import AccountDetails from "./pages/account-details";
import EnachDetail from "./pages/enach-detail";
import BankList from "./pages/bank-list";
import Leads from "./pages/Leads";
import Services from "./pages/Services";
import QrCode from "./pages/QrCode";
import Blog from "./pages/Blog"

function App() {
  const { token } = useSelector((state) => state.auth);

  return (
    <>
      <BrowserRouter>
        {/* Routes with header and sidebar */}
        {token ? (
          <LayoutContext>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/clients" element={<ClientList />} />
              <Route path="/packages" element={<PackageList />} />
              <Route path="/employees" element={<EmployeeList />} />
              <Route path="/bookings" element={<BookingList />} />
              <Route path="/account" element={<AccountDetails />} />
              <Route path="/enach" element={<EnachDetail />} />
              <Route path="/banks/:ID" element={<BankList />} />
              <Route path="/leads/:id" element={<Leads />} />
              <Route path="/services" element={<Services />} />
              <Route path="/qr-code" element={<QrCode />} />
              <Route path="/Blog" element={<Blog />} />
            </Routes>
          </LayoutContext>
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
